import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"
import {PROJECTS} from "../saas";

const Primo = () => {



  return (
    <TechSoftLayout
      title="Primo POS"
      //bannerImage="ragazzo-al-portatile-connessione-wifi"
      descriptionType="onTheLeft"
      metaDescription="Primo POS software per ristoranti. Il cliente potrà visualizzareil menù e il pagamento online e tu gestire gli ordini.Asporto o delivery, soddisfa ogni richiesta"
      PROJECTS={PROJECTS}
      description={`Primo POS è la nuova app di ACME Produzioni per il settore della ristorazione,
      che permette la visualizzazione del menù e la gestione degli ordini.
      Che i clienti siano seduti al tavolo, o che vogliano ordinare comodamente
      da casa un asporto o richiedere una consegna a domicilio potrai accontentare
      ogni loro richiesta, garantendo anche il pagamento online.`}
      contents={[{
        text: `Nasce per fronteggiare le esigenze di ristoratori e clienti, in un
        periodo di cambiamento nel pieno rispetto del nuovo protocollo
        sicurezza COVID-19.
                
        Con Primo POS sarà possibile caricare e gestire diversi menu (Menù
        Ristorante/Pizzeria, Carta dei Vini, Carta dei Cocktail, etc.),
        contemporaneamente o dividendoli per fasce orarie.`,
        image:"primo-logo"
        },
        {
        text: `Per l’ordine da casa, il cliente tramite la scansione del QRCode presente sui tuoi canali di comunicazione, 
        o cliccando sull’apposito link, potrà accedere al menù dedicato all’asporto e procedere all’ordine in totale autonomia.

        Primo POS renderà più veloce la consegna o il ritiro dell’ordine grazie al pagamento online anticipato, offrendoti anche una 
        maggiore garanzia per la certezza dei tuoi ordini.

        Al ristorante invece, il cliente troverà sul tavolo il QRCode che potrà scansionare tramite lo smartphone, e così visualizzare il menù. 
        Non sarà necessario scaricare alcuna applicazione.

        L'ordine arriverà direttamente nel BackOffice e sarà segnalato attraverso una notifica sonora e testuale. Inoltre con una stampante dedicata, 
        si ha l'opportunità di impostare la stampa di ogni singolo ordine (Hardware da acquistare a parte).
        
        Per le principali funzionalità, non è necessario l’acquisto di nessun hardware aggiuntivo, basta un semplice Tablet o PC per processare e chiudere gli ordini.
        Il menù è aggiornabile da backoffice, ed interamente personalizzabile con logo e colori, fotografie, etc..`,
        image:"primo-screen",
        linkText:"Vai al sito",
        to:"https://primopos.it/"
        }
      ]}
      // firstImage={"cau/phone1.png"}
      // secondImage={"cau/phone2.png"}
    />
  )
}

export default Primo
